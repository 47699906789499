<script>
/******************************************************************************
 * Title Bar
 *****************************************************************************/
import Icon from "@/components/Icon/Icon";
import {CLIENT_FULL, APP_SHORT_NAME, CLIENT_URL} from "@/config";
import OfflineNotification from "./OfflineNotification";
export default {
    components: {
        Icon,
        OfflineNotification
    },
    data: function () {
        return {
            appName: APP_SHORT_NAME,
            clientFull: CLIENT_FULL,
            clientUrl: CLIENT_URL
        }
    },
    computed: {
        isLoggedIn () {
            const readingLocked = this.$store.getters.sections
                .find(sec => sec.name === "meterReading").status === "locked";
            const successOk = this.$store.getters.sections
                .find(sec => sec.name === "success").status === "ok";
            const customerNo = this.$store.getters.customerNo !== "";
            const meterNo = this.$store.getters.meterNo !== "";
            return !readingLocked || successOk || customerNo || meterNo;
        }
    },
    methods: {
        logout () { this.$store.dispatch("LOGOUT"); }
    }
}
</script>

<template>
    <div class="titlebar clearfix">
        <a :href="clientUrl">
            <img
                class="logo"
                src="@/theme/client/logo.svg"
                :alt="clientFull">
        </a>
        <h1>{{ appName }}</h1>
        <offline-notification />
        <button
            v-if="isLoggedIn"
            class="logout"
            title="Abmelden"
            aria-label="Abmelden"
            @click="logout()">
            <icon name="logout" />
        </button>
    </div>
</template>

<style lang="scss" scoped>
    //$innerHeight: #{map-get($sizes, "titleBarHeight") - 20px};
    $innerHeight: 18px;
    //$innerHeightDesktop: #{map-get($sizes, "titleBarHeight") + map-get($sizes, "desktopPadding") - 20px};
    $innerHeightDesktop: 18px;

    .titlebar {
        display: flex;
        justify-content: space-between;

        overflow: hidden;
        height: map-get($sizes, "titleBarHeight");
        padding: #{map-get($sizes, "desktopPadding") / 2} 1rem;

        background: map-get($client, "titleBarBg");
        color: map-get($client, "titleBarText");

        //@include respond-to("large") {
        //    height: #{map-get($sizes, "titleBarHeight") + map-get($sizes, "desktopPadding")};
        //}

        a {
            display: flex;
            align-items: center;
        }

        .logo {
            height: $innerHeight;

            @include respond-to("large") {
                height: $innerHeightDesktop;
            }
        }

        > h1 {
            display: flex;
            align-items: center;

            margin: 0 0 0 1rem;
            flex-basis: 100%;

            font-size: #{map-get($sizes, "base") * 1.2};
            font-weight: 300;
            line-height: 1;

            @include respond-to("small") {
                font-size: #{map-get($sizes, "base") * 1.2};
                font-weight: 300;
            }

            @include respond-to("medium") {
                font-size: #{map-get($sizes, "base") * 1.4};
            }

            @include respond-to("large") {
                font-size: #{map-get($sizes, "base") * 1.6};
                line-height: 1;
            }
        }

        .logout {
            align-self: center;

            width: 36px;
            height: 36px;
            padding: 4px;
            border: 2px solid map-get($client, "titleBarButtonBg");
            margin: 0 0 0 10px;

            background-color: map-get($client, "titleBarButtonBg");
            color: map-get($client, "titleBarButtonText");

            text-align: center;

            transition:
                background-color map-get($animation-speeds, "fast") linear,
                color map-get($animation-speeds, "fast") linear;

            &:hover {
                background-color: transparent;
                color: map-get($client, "titleBarButtonBg");

                cursor: pointer;
            }
        }
    }
</style>
