<script>
/******************************************************************************
 * Header Component
 *****************************************************************************/
import NavBar from "./NavBar/NavBar";
import TitleBar from "./TitleBar/TitleBar";
export default {
    components: {
        NavBar,
        TitleBar
    }
}
</script>

<template>
    <header>
        <div class="inner">
            <title-bar />
            <nav-bar />
        </div>
    </header>
</template>

<style lang="scss" scoped>
    header {
        position: fixed;
        top: 0;
        left: 50%;
        z-index: z("header");

        width: 100%;
        border-bottom: 1px solid #e6e6e6;

        transform: translateX(-50%);

        background: map-get($client, "bodyBg");

        @include respond-to("large") {
            height: auto;
            padding-top: map-get($sizes, "desktopMargin");
        }

        .inner {
            width: 100%;
            max-width: map-get($breakpoints, "large");
            margin: 0 auto;
        }
    }
</style>
