<script>
/******************************************************************************
 * Submit Email
 *****************************************************************************/
import PageSection from "@/components/PageSection/PageSection";
import FormRow from "@/components/FormRow/FormRow";
import Icon from "@/components/Icon/Icon";
import RequestProgressing from "@/components/RequestProgressing/RequestProgressing";
import EmailErrors from "./EmailErrors";
import EmailDone from "./EmailDone";
const EVENTS = ["online", "offline", "load"];
export default {
    data: function () {
        return {
            email: this.$store.getters.email,
            isOnline: window.navigator.onLine || true
        }
    },
    components: { PageSection, FormRow, Icon, RequestProgressing, EmailErrors, EmailDone },
    computed: {
        customerEmail () {
            return this.$store.getters.email
        },
        isSubmitting () {
            return this.$store.getters.emailSubmitting;
        },
        isSubmitDisabled () {
            return this.isSubmitting
                || this.customerEmail.length < 5
                || this.customerEmail.indexOf("@") === -1;
        },
        emailErrors () {
            return this.$store.getters.emailErrors;
        },
        submissionDone () {
            return this.$store.getters.emailSubmissionDone;
        }
    },
    methods: {
        updateOnlineStatus: function() {
            if (typeof window.navigator.onLine === "undefined") {
                this.isOnline = true;
            } else {
                this.isOnline = window.navigator.onLine;
            }
        },
        submitEmail () {
            this.$store.dispatch("SUBMIT_EMAIL", {
                customerId: this.$store.getters.customerId,
                customerNo: this.$store.getters.customerNo,
                clientId: this.$store.getters.clientId,
                email: this.$store.getters.email
            });
        },
        updateEmail () {
            this.$store.dispatch("UPDATE_EMAIL", this.email);
        }
    },
    mounted () {
        EVENTS.forEach(event => window.addEventListener(event, this.updateOnlineStatus));
    },
    beforeDestroy() {
        EVENTS.forEach(event => window.removeEventListener(event, this.updateOnlineStatus));
    }
}
</script>

<template>
    <page-section
        class="email"
        hdl="Haben wir Ihre E-Mail-Adresse?"
        v-if="isOnline">
        <p>Wenn wir Ihre E-Mail-Adresse noch nicht haben können Sie uns diese hier mitteilen.</p>
        <p>
            Sie erhalten dann die Bestätigung Ihres Zählerstandes bequem per E-Mail
            und schonen die Umwelt.
        </p>
        <form-row
            ref-id="email"
            label="E-Mail-Adresse"
            v-if="!submissionDone">
            <template slot="input">
                <div class="submit-email">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        class="mail-input"
                        placeholder="E-Mail"
                        v-model="email"
                        maxlength="80"
                        @keyup.enter="submitEmail()"
                        @keyup="updateEmail()"
                        required>
                    <div class="icon">
                        <icon
                            v-if="!isSubmitting"
                            name="email" />
                        <request-progressing v-if="isSubmitting" />
                    </div>
                </div>
                <email-errors
                    v-if="emailErrors.length > 0"
                    :error-messages="emailErrors" />
                <button
                    type="button"
                    :disabled="isSubmitDisabled"
                    :aria-disabled="isSubmitDisabled"
                    @click="submitEmail()">
                    <request-progressing v-if="isSubmitting" />
                    E-Mail-Adresse melden
                    <icon
                        class="icon-next"
                        name="next" />
                </button>
            </template>
        </form-row>
        <email-done v-if="submissionDone" />
        <p>
            Wir behandeln Ihre E-Mail-Adresse wie alle persöhnlichen Daten von Ihnen nach
            unserer geltenden Datenschutzrichtlinie.<br>
            Mit der Übertragung Ihrer E-Mail stimmen Sie zu von uns per E-Mail
            wegen der Belieferung mit Energie kontaktiert zu werden.
        </p>
    </page-section>
</template>

<style lang="scss" scoped>
    .email.email {
        padding: #{map-get($sizes, "base")};
        margin: #{map-get($sizes, "base")} 0 0 0;

        background: map-get($client, "emailSectionBg");
    }

    .submit-email {
        display: flex;

        max-width: map-get($breakpoints, "small");

        .mail-input[type=email] {
            max-width: #{map-get($breakpoints, "small") - 50px};
            border-right-width: 0;

            border-radius: map-get($client, "borderRadius") 0 0 map-get($client, "borderRadius");

            &:focus + .icon {
                background-color: darken(map-get($client, "inputBg"), 10%);
                color: palette("grey", "black");
                outline: 0;
                border-color: darken(map-get($client, "inputBg"), 15%);
            }
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 50px;
            padding: #{map-get($sizes, "base") * 0.5} 2px;
            border: #{map-get($sizes, "border")} solid map-get($client, "inputBorder");
            border-left-width: 0;
            //margin-left: auto;

            background-color: map-get($client, "inputBg");
            color: map-get($client, "inputText");
            border-top-right-radius: map-get($client, "borderRadius");
            border-bottom-right-radius: map-get($client, "borderRadius");
            box-shadow:
                inset -2px 2px 0 map-get($client, "appBg"),
                inset -2px -2px 0 map-get($client, "appBg");

            line-height: 1.4;
            text-align: center;

            transition:
                background-color map-get($animation-speeds, "fast") linear,
                border-color map-get($animation-speeds, "fast") linear,
                color map-get($animation-speeds, "fast") linear;

            svg.circular {
                display: inline-block;

                width: 24px;
                height: 24px;
                margin-right: 0;
            }
        }
    }

    button {
        padding: 0 #{map-get($sizes, "base") * 0.8};
        border: map-get($sizes, "border") solid map-get($client, "buttonBg");
        margin-top: #{map-get($sizes, "base") / 2};

        background-color: map-get($client, "buttonBg");
        color: map-get($client, "buttonText");
        border-radius: map-get($client, "borderRadius");
        box-shadow:
            inset 2px 2px 0 map-get($client, "appBg"),
            inset -2px 2px 0 map-get($client, "appBg"),
            inset 2px -2px 0 map-get($client, "appBg"),
            inset -2px -2px 0 map-get($client, "appBg");

        cursor: pointer;

        font-size: map-get($sizes, "base");
        line-height: #{map-get($sizes, "base") * 2.5};

        transition:
            background-color map-get($animation-speeds, "fast") linear,
            border-color map-get($animation-speeds, "fast") linear,
            color map-get($animation-speeds, "fast") linear;

        &:hover,
        &:focus {
            background-color: darken(map-get($client, "buttonBg"), 15%);
            color: palette("grey", "white");
            outline: 0;
            border-color: darken(map-get($client, "buttonBg"), 15%);
        }

        &[disabled] {
            opacity: 0.8;

            cursor: not-allowed;
        }

        @include respond-to("small") { padding: 0 #{map-get($sizes, "base") * 1.2}; }
    }
</style>
