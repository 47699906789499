<script>
/******************************************************************************
 * Footer Component
 *****************************************************************************/
import {CLIENT_FULL, CLIENT_URL} from "@/config";
export default {
    data: function () {
        return {
            clientFull: CLIENT_FULL,
            clientUrl: CLIENT_URL
        }
    },
}
</script>

<template>
    <footer>
        <div class="inner clearfix">
            <ul
                role="navigation"
                class="clearfix">
                <li>
                    <a
                        class="footer-link"
                        href="https://www.bhag.de/impressum">Impressum</a>
                </li>
                <li>
                    <a
                        class="footer-link"
                        href="https://www.bhag.de/datenschutz">Datenschutz</a>
                </li>
            </ul>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
    footer {
        border-top: 1px solid #e6e6e6;

        background: map-get($client, "footerBg");
        color: map-get($client, "footerText");
    }

    .inner {
        max-width: map-get($breakpoints, "large");
        margin: 0 auto;
    }

    a {
        display: block;

        @include respond-to("small") {
            float: left;
        }
    }

    ul {
        padding: 0;
        margin: 0;

        list-style: none;

        @include respond-to("small") {
            float: left;
        }
    }

    li {
        position: relative;
        float: left;

        &:first-child {
            float: left;
        }

        &:not(:first-child)::before {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;

            width: 1px;
            height: 20px;

            transform: translateY(-50%);

            background: lighten(map-get($client, "footerBg"), 12%);

            content: " ";
        }
    }

    .footer-link {
        display: block;

        padding: 1rem;

        color: map-get($client, "footerText");

        transition: background-color map-get($animation-speeds, "fast") linear;

        &:hover {
            background-color: darken(map-get($client, "footerBg"), 10%);

            text-decoration: none;
        }
    }
</style>
